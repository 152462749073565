import BaseBean from "@/utils/BaseBean";

export interface IHdgsListDataObj {
    utilInst:HdgsListUtil
    refMap:Map<string,any>
    pageListRef:any
    pageList: any
    otherParams: any
}

export default class HdgsListUtil extends BaseBean{
    public dataObj:IHdgsListDataObj

    constructor(proxy:any,dataObj:IHdgsListDataObj) {
        super(proxy);
        this.dataObj=dataObj;
    }
}